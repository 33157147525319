.tabs-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
}

.tabs-container::-webkit-scrollbar-track
{
	background-color: #D9D9D6;
}

.tabs-container::-webkit-scrollbar
{
	height: 1px;
	background-color: #D9D9D6;
}

.tabs-container::-webkit-scrollbar-thumb
{
	background-color: #4FB265;
	border: 2px solid #4FB265;
}

.tab {
    margin: 20px 6px 30px;
    padding: 14px;
    width: 250px;
    font-family: 'Albert Sans', sans-serif;
    min-width: max-content;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #3d3d3d;
    background-color: #fff;
    border: none;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: all 0.6s;
}

.tab:hover {
    background-color: #f3f3f2;
}

.tab.active {
    color: #ffffff;
    background-color: #4FB265;
}

@media (max-width: 990px) {
    .tabs-container {
        justify-content: flex-start;
    }

    .tab {
        padding: 10px 20px;
        font-size: 16px;
    }
}
