.form-group .option:not(:last-child) {
    border-bottom: 1px solid #F3F3F2;
}

.option * {
    cursor: pointer;
}

.option {
    padding: 16px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.option input {
    display: none;
}

.radio {
    min-width: 20px;
    min-height: 20px;
    border: 1px solid #4FB265;
    border-radius: 50%;
}

.radio.selected {
    background-image: url(../../../public/checked.svg);
    background-position: center center;
    background-size: cover;
}

.other-field {
    width: 100%;
    padding: 14px 25px;
    border: 1px solid #f1f1fa;
    border-radius: 10px;
    font-family: 'Albert Sans', sans-serif;
}