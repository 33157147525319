.red {
    color: red;
}

.disclaimer {
    padding: 10px;
    color: #7d7d7d;
    font-size: 14px;
}

.text-green td {
    color: #4FB265 !important;
}

#naves .totales {
    font-size: 1.2em;
}

#naves .totales td {
    padding-top: 12px;
    color: #00603D
}

#naves .totales td:first-of-type {
    color: #3d3d3d;
}

@media (max-width: 800px) {
    #naves thead tr th {
        font-size: 12px;
    }

    #naves tbody {
        font-size: 14px;
    }

    #naves .totales {
        font-size: 1em;
    }
    
    #naves .totales td {
        padding-top: 10px;
    }
}
