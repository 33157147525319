.currency-container {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
}

.currency-switch {
    height: 42px;
    width: 220px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #f3f3f2;
    border-radius: 21px;
}

.currency-switch label {
    width: 110px;
    display: block;
    position: relative;
    text-align: center;
    color: #3d3d3d;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.6s;
}

.currency-switch label.active {
    color: #fff;
}

.switch-marker {
    width: 110px;
    height: 42px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #4FB265;
    border-radius: 21px;
    transition: all 0.6s;
}

.switch-marker.usd {
    left: 50%;
}

.currency-input {
    width: 220px;
    max-width: 100%;
    margin-top: 10px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9D9D6;
    border-radius: 20px;
}

.currency-input:hover {
    border-color: #4FB265;
}

.currency-input label {
    color: #3d3d3d;
}

.currency-input input {
    width: calc(100% - 10px);
    padding: 5px 10px;
    color: #3d3d3d;
    font-family: 'Albert Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    border: none;
    outline: none;
}