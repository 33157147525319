.inversion-table {
    width: 100%;
    margin-bottom: 30px;
    position: relative;
    border-radius: 20px; 
    box-shadow: 0 0 15px rgba(0,0,0,0.15);
    overflow: hidden; 
    border-collapse: collapse;
}

.inversion-table th {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 18px;
    color: #3D3D3D;
    text-align: left;
    font-weight: 500;
}

.inversion-table tr td:first-of-type, .inversion-table tr th:first-of-type {
    padding-left: 40px;
}

.inversion-table td {
    padding: 4px 0;
    color: #3D3D3D;
    font-weight: 600;
}

.inversion-table .green {
    background-color: #4FB265;
}

.inversion-table .green td {
    padding: 4px 0;
    color: #4FB265;
    font-weight: 700;
}

.inversion-table tr:not(:last-of-type) {
    border-bottom: 1px solid #3d3d3d;
}

.inversion-table tr:not(:last-of-type) td {
    border-bottom: 1px solid #fafafa;
}

.inversion-table tr:last-of-type td {
    padding-bottom: 20px;
}

.inversion-table tr.green {
    background-color: #00603D15;
}

@media (max-width: 768px) {
    .inversion-table::after {
        right: -80px;
        font-size: 16px;
    }

    .inversion-table th {
        font-size: 16px;
    }

    .inversion-table tr td:first-of-type, .inversion-table tr th:first-of-type {
        padding-left: 20px;
    }
}