.ammount-select {
    max-height: 180px;
    overflow-y: auto;
}

.ammount-select::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 2px;
}
.ammount-select::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}

.ammount-select::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #fff;
    background-image: -webkit-linear-gradient(
        top,
        #e4f5fc 0%,
        #95c11f 25%,
        #008D36 75%,
        #004F23 100%
    );
}
