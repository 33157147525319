.result-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: #fff;
    padding: 50px 100px;
    border-radius: 20px;
    box-shadow: 0 0 150px 150px rgba(0, 0, 0, 0.25);
    color: #3d3d3d;
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    z-index: 99;
}

.result-popup h3,
.result-popup h4 {
    margin: 5px 0;
    text-align: center;
}

.result-popup h3 {
    font-weight: 500;
    font-size: 22px;
}

.result-popup h4 {
    font-weight: 700;
    font-size: 40px;
}

.result-popup h5 {
    margin: 5px 0;
    font-weight: 700;
    font-size: 16px;
}

.result-popup ul {
    list-style-type: none;
    padding-left: 0;
}

.result-popup button {
    margin-top: 50px;
}

.close-icon {
    position: absolute; 
    top: 30px;
    right: 30px;
    color: #B4B4B4;
    cursor: pointer;
}

.close-icon:hover {
    color: #004f23;
}

@media screen and (max-width: 990px) {
    .result-popup {
        padding: 40px 70px;
    }
}

@media screen and (max-width: 768px) {
    .result-popup {
        padding: 35px;
        width: 95%;
    }
}

@keyframes fadeInUp {
    from {
        transform: translate(-50%,50%)
    }

    to {
        transform: translate(-50%,-50%);
        opacity: 1
    }
}