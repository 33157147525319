#green-circle {
    width: 100px;
    position: absolute;
    left: 50px;
    top: 100px;
}

#green-dot {
    max-width: 20px;
    position: absolute;
    left: 150px;
    top: 200px;
}

#right-lines {
    max-width: 200px;
    position: absolute;
    right: 0;
    top: 1200px;
}

#circle-lens {
    max-width: 100px;
    position: absolute;
    left: 50px;
    top: 2000px;
}

#two-cells {
    max-width: 150px;
    position: absolute;
    right: 0;
    top: 90%;
}

@media screen and (max-width: 990px) {
    #two-cells, #circle-lens, #right-lines {
        display: none;
    }

    #green-circle {
        max-width: 100px;
    }

    #green-circle {
        max-width: 80px;
        left: 10px;
    }

    #green-dot {
        top: 100px;
        left: 100px;
    }
}

@media screen and (max-width: 768px) {
    #green-circle {
        max-width: 60px;
        top: 10px;
        left: 10px;
    }

    #green-dot {
        top: 10px;
        left: 70px;
    }
}