.perfil {
    padding: 80px 0;
    position: relative;
    background-image: url('../../../public/arco.svg');
    background-size: contain;
    background-position: top left;
    background-repeat: no-repeat;
    background-color: #f9f9f9;
}

.form {
    margin-top: 80px;
    position: relative;
    z-index: 9;
}

.green-text {
    color: #4FB265 !important;
}

.intro {
    font-size: 20px;
    text-align: center;
    color: #3D3D3D;
    max-width: 600px;
    margin: 0 auto;
}

.form-group {
    margin: 20px 0;
    padding: 35px 65px;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    background-color: #fff;
}

.form-group h2 {
    margin-top: 0;
    color: #4FB265;
}

.form-group .underline {
    background-color: #4FB265;
    height: 1px;
}

.form-row {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

.rounded-input {
    width: 50%;
    padding: 17px 22px;
    display: flex;
    border: 1px solid #cdcdcd;
    border-radius: 20px;
}

.rounded-input label, .rounded-input input {
    width: 50%;
}

.rounded-input input {
    border: none;
    outline: none;
}

.submit-btn {
    padding: 15px 32px;
    margin: 0 auto;
    border-radius: 25px;
    display: flex;
    gap: 6px;
    background-color: #4FB265;
    color: white;
    font-weight: 700;
    align-items: center;
    border: none;
    outline: none;
    transition: all 0.3s ease-in;
    cursor: pointer;
    text-decoration: none;
}

.submit-btn:hover {
    background-color: #004F23;
    gap: 10px;
}


@media screen and (max-width: 768px) {
    .perfil {
        padding: 60px 0;
    }

    .form {
        margin-top: 40px;
    }

    .form-group {
        padding: 20px 30px;
    }

    .form-group h2 {
        font-size: 18px;
    }

    .rounded-input {
        width: 100%;
        padding: 14px 20px;
    }

    .form-row {
        flex-direction: column;
    }

    .rounded-input label {
        font-size: 14px;
    }
}