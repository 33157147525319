.selector {
    max-width: 100%;
    position: relative;
    margin-left: 30px;
    padding: 10px 30px;
    border: 1px solid #D9D9D6;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
}

.selector:hover {
    border: 1px solid #4FB265;
}

.selected::after {
    content: "";
    width: 7px;
    height: 7px;
    position: absolute;
    top: 45%;
    right: 30px;
    border: 3px solid #008D36;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg) translateY(-50%);
    z-index: 9;
    transition: all 0.3s;
}

.selected h4, .selected p {
    margin: 0;
}

.selected h4 {
    margin-bottom: 5px;
    padding-right: 15px;
    color: #4FB265;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.selected p {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
    color: #3d3d3d;
}

.dropdown {
    margin-top: 10px;
    padding: 10px 0;
    display: none;
    border-radius: 15px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.15);
    background-color: #fff;
    z-index: 99;
    transition: all 0.6s;
}

.dropdown.open {
    display: block;
}

.dropdown .option {
    padding: 5px 20px;
    cursor: pointer;
    transition: 0.3s;
}

.dropdown .option:hover {
    background-color: #4FB265;
}