.label {
    display: flex;
    align-items: center;
}

.label.center {
    justify-content: center;
}

.label .index {
    font-size: 35px;
    font-weight: 700;
    color: #D9D9D6;
}

.label h2 {
    margin-left: 10px;
    font-size: 18px;
    color: #00603D;
}