@font-face {
    font-family: NeulisAltRegular;
    src: local("NeulisAltRegular"), url(./fonts/NeulisAlt-Regular.otf);
}
@font-face {
    font-family: NeulisAltBold;
    src: local("NeulisAltBold"), url(./fonts/NeulisAlt-Bold.otf);
}
@font-face {
    font-family: NeulisAltItalic;
    src: local("NeulisAltItalic"), url(./fonts/NeulisAlt-Italic.otf);
}

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "NeulisAltRegular", sans-serif;
}

.container {
  width: 95%;
  max-width: 1140px;
  margin: 0 auto;
}

.app h1 {
  text-align: center;
  font-size: 50px;
  color: #3D3D3D;
}

@media (max-width: 990px) {
  .app h1 {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .app h1 {
    font-size: 32px;
  }
}

.text-black{
    font-family: "NeulisAltBold", sans-serif;
}

.bold{
    font-weight: bold;
}