.tab-content {
    padding: 30px;
    display: flex;
}

.tab-data {
    width: 35%;
    padding-right: 40px;
}

.tab-results {
    width: 65%;
    padding-left: 40px;
    border-left: 1px solid rgba(0,0,0,0.1);
}

@media screen and (max-width: 990px) {
    .tab-content {
        padding: 30px 15px;
    }

    .tab-data {
        padding-right: 20px;
    }
    
    .tab-results {
        padding-left: 20px;
    }
}

@media screen and (max-width: 990px)  {
    .tab-content {
        flex-direction: column;
    }

    .tab-data {
        width: 100%;
        padding: 0;
    }

    .tab-results {
        width: 100%;
        margin-top: 20px;
        padding: 20px 0;
        border: none;
        border-top: 1px solid rgba(0,0,0,0.1);
    }
}