.fondos-table {
    width: 100%;
    position: relative;
    border-radius: 20px; 
    box-shadow: 0 0 15px rgba(0,0,0,0.15);
    overflow: hidden; 
    border-collapse: collapse;
}

.fondos-table::after {
    content: "Premios por permanencia";
    position: absolute;
    bottom: 120px;
    right: -60px;
    font-size: 18px;
    font-weight: 600;
    color: #00603D;
    transform: rotate(90deg);
}

.fondos-table th {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 18px;
    color: #3D3D3D;
    text-align: left;
    font-weight: 500;
}

.fondos-table tr td:first-of-type, .fondos-table tr th:first-of-type {
    padding-left: 40px;
}

.fondos-table td {
    padding: 4px 0;
    color: #4FB265;
    font-weight: 700;
}

.fondos-table tr:not(:last-of-type) {
    border-bottom: 1px solid #3d3d3d;
}

.fondos-table tr:not(:last-of-type) td {
    border-bottom: 1px solid #fafafa;
}

.fondos-table tr:last-of-type td {
    padding-bottom: 20px;
}

.fondos-table tr.green {
    background-color: #00603D15;
}

@media (max-width: 768px) {
    .fondos-table::after {
        right: -80px;
        font-size: 16px;
    }

    .fondos-table th {
        font-size: 16px;
    }

    .fondos-table tr td:first-of-type, .fondos-table tr th:first-of-type {
        padding-left: 20px;
    }
}