.horizontal-input {
    margin-top: 20px;
    margin-left: 30px;
    display: flex;
    align-items: center;
}

.horizontal-input label {
    width: 40%;
    display: block;
    color: #00603D;
    font-size: 18px;
    font-weight: 700;
}

.horizontal-input .input-wrapper {
    width: 60%;
    position: relative;
}

.horizontal-input input {
    max-width: 100%;
    display: block;
    padding: 12px 30px;
    color: #3D3D3D;
    border: 1px solid #D9D9D6;
    border-radius: 17px;
    font-family: 'Albert Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    outline: none;
}

.horizontal-input input:focus {
    border-color: #4FB265;
}

.input-wrapper.ammount.gs::after {
    content: "G.";
    position: absolute;
    color: #3D3D3D;
    font-size: 14px;
    top: 16px;
    left: 10px;
}

.input-wrapper.ammount.usd::after {
    content: "USD";
    position: absolute;
    color: #3D3D3D;
    font-size: 14px;
    top: 16px;
    left: 10px;
}