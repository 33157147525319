.resume {
    padding-left: 40px;
    padding-bottom: 10px;
    display: flex;
}

.resume div {
    flex: 1;
    color: #3d3d3d;
}

.resume div h3 {
    font-weight: 500;
    font-size: 18px;
    color: #3d3d3d;
    opacity: .8;
}

.resume div p {
    font-weight: 700;
    font-size: 18px;
}

.results {
    padding: 30px 40px;
    margin-bottom: 30px;
    color: #3d3d3d;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
}

.results #anual {
    display: inline-block;
    width: 40%;
}

.results #period {
    display: inline-block;
    width: 60%;
}

.results #total {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    padding-top: 18px;
}

.results h3 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    color: #3d3d3d;
    opacity: .8;
}

.results p {
    margin-top: 8px;
    color: #4FB265;
    font-weight: 700;
    font-size: 18px;
}

.results #total p {
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 500;
   
}

@media screen and (max-width: 990px) {
    .resume {
        flex-direction: column;
    }

    .resume div {
        display: flex;
        align-items: center;
    }

    .resume p,
    .resume h3 {
        margin: 0;
        margin-bottom: 7px;
    }

    .resume p {
        margin-left: 25px;
    }

    .results #anual,
    .results #period {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .resume {
        padding: 0;
        margin-bottom: 20px;
    }

    .results {
        padding: 20px;
    }
}